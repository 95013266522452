<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
<!--    <el-breadcrumb-item></el-breadcrumb-item>-->
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    <el-breadcrumb-item>用户列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图区域 -->
  <el-card>
    <div class="search">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
        <el-form-item label="用户名">
          <el-input placeholder="请输入用户名" v-model="searchForm.username" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input placeholder="请输入昵称" v-model="searchForm.nickname" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-select v-model="searchForm.cus_id" placeholder="请选择公司" clearable>
            <el-option
              v-for="item in this.customerList"
              :key="item.name"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.create_time" type="daterange"
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 250px">
          </el-date-picker>
        </el-form-item>
      </el-form>
      </div>
    </el-card>
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchUser"  size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" @click="addDialogVisible=true" size="small" icon="el-icon-plus">新增</el-button>
      </el-button-group>
       <el-table
        :data="tableData"
        style="width: 100%" stripe>
        <el-table-column width="60px" fixed
          label="ID"
          prop="id">
        </el-table-column>
        <el-table-column fixed
          label="用户名" width="120px"
          prop="username">
        </el-table-column>
        <el-table-column
          label="昵称" width="120px"
          prop="nickname">
        </el-table-column>
         <el-table-column
          label="所属公司" width="250px"
          prop="cus_id" :formatter="formatCustomerName" :show-overflow-tooltip="true">
        </el-table-column>
         <el-table-column
          label="角色" width="100px"
          prop="role_id" :formatter="formatRoleName">
        </el-table-column>
         <el-table-column
          label="是否启用" width="80px"
          prop="is_enable">
           <template slot-scope="scope">
             <el-switch :disabled="true"
              v-model="scope.row.is_enable"
              active-color="#409EFF"
              inactive-color="#C0CCDA"
              :active-value="1"
              :inactive-value="0"></el-switch>
           </template>
        </el-table-column>
           <el-table-column
          label="创建时间" width="165px"
          prop="create_time" :formatter="formatDatetime">
        </el-table-column>
         <el-table-column
          label="更新时间" width="165px"
          prop="update_time" :formatter="formatDatetime">
        </el-table-column>
        <el-table-column width="280px" fixed="right"
          align="left" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="handleDelete(scope.$index, scope.row)">
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    slot="reference">删除</el-button>
              </el-popconfirm>
              <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要重置密码吗？"
                @confirm="resetPWD(scope.row)">
                  <el-button
                    size="mini"
                    type="warning"
                    icon="el-icon-setting"
                    slot="reference">重置密码</el-button>
              </el-popconfirm>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
     <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
    <!-- 新增用户对话框 -->
     <el-dialog title="添加用户" :visible.sync="addDialogVisible" @close="addDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username" ></el-input>
        </el-form-item>
        <el-form-item label="昵称"  prop="nickname">
          <el-input v-model="addForm.nickname" ></el-input>
        </el-form-item>
        <el-form-item label="所属客户"  prop="cus_id" :show-overflow-tooltip="true">
          <el-select v-model="addForm.cus_id" placeholder="请选择用户所属客户">
            <el-option
              v-for="item in this.customerList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="addForm.cus_id == 2" label="所属部门"  prop="cus_id">
          <el-select v-model="addForm.dept_id" placeholder="请选择用户所属部门">
            <el-option
              v-for="item in this.departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色"  prop="role_id">
          <el-select v-model="addForm.role_id" placeholder="请选择用户角色">
            <el-option
              v-for="item in this.roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
        <el-form-item label="是否启用"  prop="is_enable">
             <el-switch v-model="addForm.is_enable" active-color="#409EFF" inactive-color="#C0CCDA" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">保 存</el-button>
      </div>
    </el-dialog>

    <!-- 修改用户对话框 -->
     <el-dialog title="修改用户信息" :visible.sync="editDialogVisible" @close="editDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="80px">
        <el-form-item label="用户名">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="昵称"  prop="nickname">
          <el-input v-model="editForm.nickname" ></el-input>
        </el-form-item>
        <el-form-item label="所属客户"  prop="cus_id">
          <el-select v-model="editForm.cus_id" placeholder="请选择用户所属客户">
            <el-option
              v-for="item in this.customerList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="editForm.cus_id == 2" label="所属部门"  prop="cus_id">
          <el-select v-model="editForm.dept_id" placeholder="请选择用户所属部门">
            <el-option
              v-for="item in this.departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select v-model="editForm.role_id" placeholder="请选择用户角色">
            <el-option
              v-for="item in this.roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
        <el-form-item label="是否启用"  prop="is_enable">
         <el-switch v-model="editForm.is_enable" active-color="#409EFF" inactive-color="#C0CCDA" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">保 存</el-button>
      </div>
    </el-dialog>
  </el-card>
    </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Users',
  data () {
    return {
      value: true,
      tableData: [],
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        total: 0
      },
      editDialogVisible: false,
      addDialogVisible: false,
      id: '',
      /** 客户字典 */
      customerList: [],
      /** 角色字典 */
      roleList: [],
      /** 部门字典 */
      departmentList: [],
      /** 添加用户表单对象 */
      addForm: {
        id: 0,
        username: '',
        nickname: '',
        cus_id: null,
        role_id: 0,
        is_enable: 1,
        dept_id: null
      },
      /** 新增用户数据检查 */
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在5～20个字符之间', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '请输入用户昵称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
        ],
        role_id: [
          { required: true, message: '请选择用户角色', trigger: 'blur' }
        ],
        cus_id: [
          { required: true, message: '请选择用户所属客户', trigger: 'blur' }
        ],
        is_enable: [
          { required: true, message: '请选择是否禁用用户', trigger: 'blur' }
        ]
      },
      /** 修改用户表单对象 */
      editForm: {},
      editFormRules: {
        nickname: [
          { required: true, message: '请输入用户昵称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
        ],
        role_id: [
          { required: true, message: '请选择用户角色', trigger: 'blur' }
        ],
        cus_id: [
          { required: true, message: '请选择用户所属客户', trigger: 'blur' }
        ],
        is_enable: [
          { required: true, message: '请选择是否禁用用户', trigger: 'blur' }
        ]
      },
      /** 查询表单对象 */
      searchForm: {
        username: null,
        nickname: null,
        cus_id: null,
        create_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
      },
      searchFormRules: {

      }
    }
  },
  created: function () {
    // this.$message('select = '+this.select+"--keyword = "+this.keyword)
    this.getUserList(this.queryInfo)
    this.getCustomerList()
    this.getRoleList()
    this.getDepartmentList()
  },
  methods: {
    getDepartmentList: function () {
      this.$http.get('/department/list').then(
        res => {
          this.departmentList = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getRoleList: function () {
      this.$http.get('/role/list').then(
        res => {
          this.roleList = res.data.data.rows
          // console.log(this.roleList)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getCustomerList: function () {
      this.$http.get('/customer/list').then(
        res => {
          this.customerList = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    searchUser: function () {
      this.getUserList(this.queryInfo)
    },
    /** 格式化角色名称 */
    formatRoleName: function (row) {
      let role
      for (let i = 0; i < this.roleList.length; i++) {
        role = this.roleList[i]
        if (role.id === row.role_id) {
          return role.name
        }
      }
    },
    /** 格式化客户名称 */
    formatCustomerName: function (row) {
      let customer
      for (let i = 0; i < this.customerList.length; i++) {
        customer = this.customerList[i]
        // console.log('i=' + i)
        if (customer.id === row.cus_id) {
          // console.log(customer)
          return customer.name
        }
      }
    },
    /** 格式化时间戳为标准的时间字符串 */
    formatDatetime: function (row, column, cellValue) {
      return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
    },
    /** 添加或者更新用户信息，通过用户ID来判断 */
    saveUpdateUser (form) {
      this.$http.post('/user', JSON.stringify(form)).then(
        res => {
          // console.log('res :' + res)
          this.$message.success({ message: res.data.msg })
          this.addDialogVisible = false
          this.editDialogVisible = false
          this.getUserList(this.queryInfo)
        },
        err => {
          // console.log('err.response.data :' + JSON.stringify(err.response.data))
          this.$message.error({ message: err.response.data.msg })
        })
    },
    /** 添加用户 */
    addUser () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        if (this.addForm.cus_id === 2) {
          if (!this.addForm.dept_id) {
            this.$message.error('请选择所属部门')
            return
          }
        }
        this.saveUpdateUser(this.addForm)
      })
    },
    /** 显示编辑用户信息的对话框 */
    handleEdit (index, row) {
      this.editDialogVisible = true
      this.$http.get(`/user/${row.id}`).then(
        res => {
          // console.log("res :", res);
          this.editForm = res.data.data
          this.editDialogVisible = true
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    handleDelete (index, row) {
      this.$http.delete(`/user/${row.id}`).then(
        res => {
          this.getUserList(this.queryInfo)
          this.$message({ message: res.data.msg, type: 'success' })
        },
        err => {
          // console.log('err: ' + JSON.stringify(err))
          this.$message({ message: err.response.data.msg, type: 'error' })
        }
      )
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getUserList(this.queryInfo)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.page = val
      this.getUserList(this.queryInfo)
    },
    /** 获取用户列表 */
    getUserList (queryInfo) {
      const endTime = this.searchForm.create_time
      if (endTime) {
        this.searchForm.create_time[1] = moment(endTime[1]).endOf('day').valueOf()
      }
      this.searchForm.cus_id = (this.searchForm.cus_id === '') ? null : this.searchForm.cus_id
      // console.log('搜索表单：', this.searchForm)
      this.$http.post(`/user/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
        res => {
          // console.log('this.searchForm :', JSON.stringify(this.searchForm))
          this.queryInfo.total = res.data.data.total
          this.tableData = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 监听添加用户对话框关闭事件 */
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    /** 监听修改用户对话框关闭事件 */
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    /** 修改用户信息并提交 */
    editUser () {
      this.$refs.editFormRef.validate(valid => {
        if (!valid) return
        if (this.addForm.cus_id === 2) {
          if (!this.addForm.dept_id) {
            this.$message.error('请选择所属部门')
            return
          }
        }
        this.saveUpdateUser(this.editForm)
      })
    },
    /** 重置密码 */
    resetPWD (row) {
      console.log(row.id)

      this.$http.post('/user/pwd', `{"id": ${row.id}}`).then(
        res => {
          this.$message.success({ message: res.data.msg })
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.search {
    width: 50%;
    /* margin-left: 50%; */
  }
   .el-input .el-select  {
    width: 120px;
  }
   .el-date-picker {
     width: 200px;
   }
  .el-input-group__prepend .input-with-select  {
    background-color: #fff;
  }
</style>
