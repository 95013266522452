<template>
<div style="height: 100%" >
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>用车管理</el-breadcrumb-item>
  <el-breadcrumb-item>车辆费用</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图区域 -->
  <!-- 搜索视图区 -->
  <el-card>
    <div class="search">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
        <el-form-item label="车牌号">
          <el-select v-model="searchForm.plate_no_id" filterable placeholder="请选择车牌号">
            <el-option
              v-for="item in this.plate_no_list_all"
              :key="item.plate_no"
              :label="item.plate_no"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用车单位">
          <el-select v-model="searchForm.cus_id" placeholder="请选择用车单位">
            <el-option
              v-for="item in this.customer_list_all"
              :key="item.name"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用车日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.use_car_date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px"></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <!-- 列表视图区 -->
  <el-card>
    <el-button-group>
      <el-button type="primary" @click="searchVehiclefee" size="small" icon="el-icon-search">查询</el-button>
      <el-button v-if="isDispatcherScope || isAdminScope" type="primary" @click="addDialogVisible = true" size="small" icon="el-icon-plus">新增</el-button>
      <el-button v-if="isBalanceScope || isAdminScope" type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="exportExcel" size="small" icon="el-icon-document">导出EXCEL</el-button>
    </el-button-group>
    <el-table :data="tableData" style="width: 100%" stripe show-summary :summary-method="getSummaries">
      <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
      <el-table-column label="用车日期" prop="use_car_date" width="110px" fixed="left" :formatter="formatDate"></el-table-column>
      <el-table-column label="车牌号" prop="plate_no_id" width="100px" fixed="left" :formatter="formatVehiclePlateName"></el-table-column>
      <el-table-column label="用车单位" prop="cus_id" width="250px" :show-overflow-tooltip="true" :formatter="formatCustomerName"></el-table-column>
      <el-table-column label="起始地" prop="origin_place" width="120px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="目的地" prop="dest_place" width="120px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="备注" prop="remark" width="200px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column v-if="isBalanceScope || isAdminScope" label="应收" prop="receivable" width="120px" :formatter="toDecimal2"></el-table-column>
      <el-table-column v-if="isBalanceScope || isAdminScope" label="应付" prop="payable" width="120px" :formatter="toDecimal2"></el-table-column>
      <el-table-column v-if="isBalanceScope || isAdminScope" label="成本" prop="op_cost" width="120px" :formatter="toDecimal2"></el-table-column>
      <el-table-column v-if="isBalanceScope || isAdminScope" label="结算说明" prop="b_remark" width="300px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="更新者" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column width="165px" fixed="right" align="center" label="操作">
         <template slot-scope="scope">
           <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-popconfirm
              confirm-button-text='好的'
              cancel-button-text='不用了'
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
              @confirm="handleDelete(scope.$index, scope.row)">
                <el-button v-if="!isBalanceScope"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  slot="reference">删除</el-button>
            </el-popconfirm>
           </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页视图区 -->
    <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
   </el-pagination>
    <!-- 新增车辆费用对话框 -->
    <el-dialog title="新增车辆费用" :visible.sync="addDialogVisible" @click="addDialogClosed">
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="110px">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>调度信息</span>
          <!-- 基础信息Tab -->
          <el-form-item label="车牌号" prop="plate_no_id">
            <el-select v-model="addForm.plate_no_id" filterable style="width: 180px">
              <el-option v-for="item in this.plate_no_list" :key="item.id" :value="item.id" :label="item.plate_no"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用车单位" prop="cus_id">
            <el-select v-model="addForm.cus_id" style="width: 180px">
              <el-option v-for="item in this.customer_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用车日期" prop="use_car_date">
            <el-date-picker v-model="addForm.use_car_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="起始地" prop="origin_place">
            <el-input v-model="addForm.origin_place"></el-input>
          </el-form-item>
          <el-form-item label="目的地" prop="dest_place">
            <el-input v-model="addForm.dest_place"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="addForm.remark" type="textarea" style="width: 475px"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane v-if="isBalanceScope || isAdminScope">
          <span slot="label"><i class="el-icon-tickets"></i>结算信息</span>
          <el-form-item label="应收" prop="receivable">
            <el-input v-model="addForm.receivable"></el-input>
          </el-form-item>
          <el-form-item label="应付" prop="payable">
            <el-input v-model="addForm.payable"></el-input>
          </el-form-item>
          <el-form-item label="成本" prop="op_cost">
            <el-input v-model="addForm.op_cost"></el-input>
          </el-form-item>
          <el-form-item label="结算说明" prop="remark">
            <el-input v-model="addForm.b_remark" type="textarea" style="width: 475px"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane>
        <span slot="label"><i class="el-icon-picture-outline"></i> 附件</span>
          <el-form>
            <el-form-item>
              <el-upload
                class="upload-demo"
                :on-change="uploadImageForAddVehicle"
                :auto-upload="false"
                action="#"
                :file-list="fileList"
                accept=".jpg, .jpeg, .png">
                <el-button size="small" type="primary">选择照片并上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg|jpeg|png文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-table :data="tableImageData" style="width: 100%" stripe>
            <el-table-column label="序号" prop="id" width="60px"></el-table-column>
            <el-table-column label="上传者" prop="creator" :formatter="formatUserName" width="120"></el-table-column>
            <el-table-column label="上传时间" prop="create_time" :formatter="formatDatetime" width="165px"></el-table-column>
            <el-table-column label="照片" prop="name">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="getImageUrl(scope.row.path, scope.row.name)"
                  :preview-src-list="[getImageUrl(scope.row.path, scope.row.name)]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px" fixed="right" align="left">
              <template slot-scope="scope">
                <el-popconfirm
                  confirm-button-text='好的'
                  cancel-button-text='不用了'
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定要删除吗？"
                  @confirm="delVehiclePicByGUID(scope.$index, scope.row)">
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      slot="reference">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      </el-form>
      <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVehiclefee">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 编辑车辆费用对话框 -->
    <el-dialog title="编辑车辆费用" :visible.sync="editDialogVisible" @click="editDialogClosed">
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="110px">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>调度信息</span>
          <!-- 基础信息Tab -->
          <el-form-item label="ID" prop="id">
            <el-input v-model="editForm.id" readonly></el-input>
          </el-form-item>
          <el-form-item label="车牌号" prop="plate_no_id">
            <el-select :disabled="isBalanceScope" filterable v-model="editForm.plate_no_id"  style="width: 180px">
              <el-option v-for="item in this.plate_no_list" :key="item.id" :value="item.id" :label="item.plate_no"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用车单位" prop="cus_id">
            <el-select :disabled="isBalanceScope" v-model="editForm.cus_id" style="width: 180px">
              <el-option v-for="item in this.customer_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用车日期" prop="use_car_date">
            <el-date-picker v-model="editForm.use_car_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="起始地" prop="origin_place">
            <el-input :readonly="isBalanceScope" v-model="editForm.origin_place"></el-input>
          </el-form-item>
          <el-form-item label="目的地" prop="dest_place">
            <el-input :readonly="isBalanceScope" v-model="editForm.dest_place"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input :readonly="isBalanceScope" v-model="editForm.remark" type="textarea" style="width: 475px"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane v-if="isBalanceScope || isAdminScope">
            <span slot="label"><i class="el-icon-tickets"></i>结算信息</span>
            <el-form-item label="应收" prop="receivable">
              <el-input v-model="editForm.receivable"></el-input>
            </el-form-item>
            <el-form-item label="应付" prop="payable">
              <el-input v-model="editForm.payable"></el-input>
            </el-form-item>
            <el-form-item label="成本" prop="op_cost">
              <el-input v-model="editForm.op_cost"></el-input>
            </el-form-item>
            <el-form-item label="结算说明" prop="remark">
              <el-input v-model="editForm.b_remark" type="textarea" style="width: 475px"></el-input>
            </el-form-item>
          </el-tab-pane>
        <el-tab-pane>
        <span slot="label"><i class="el-icon-picture-outline"></i> 附件</span>
          <el-form>
            <el-form-item>
              <el-upload
                class="upload-demo"
                :on-change="uploadImage"
                :auto-upload="false"
                action="#"
                :file-list="fileList"
                accept=".jpg, .jpeg, .png">
                <el-button size="small" type="primary">选择照片并上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg|jpeg|png文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-table :data="tableImageData" style="width: 100%" stripe>
            <el-table-column label="序号" prop="id" width="60px"></el-table-column>
            <el-table-column label="上传者" prop="creator" :formatter="formatUserName" width="120"></el-table-column>
            <el-table-column label="上传时间" prop="create_time" :formatter="formatDatetime" width="165px"></el-table-column>
            <el-table-column label="照片" prop="name">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="getImageUrl(scope.row.path, scope.row.name)"
                  :preview-src-list="[getImageUrl(scope.row.path, scope.row.name)]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px" fixed="right" align="left">
              <template slot-scope="scope">
                <el-popconfirm
                  confirm-button-text='好的'
                  cancel-button-text='不用了'
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定要删除吗？"
                  @confirm="delVehiclePicByID(scope.$index, scope.row)">
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      slot="reference">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      </el-form>
      <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateVehiclefee">保 存</el-button>
      </div>
    </el-dialog>
  </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Vehiclefee',
    data () {
      return {
        dialogImageUrl: '',
        fullscreenLoading: false,
        addDialogVisible: false,
        editDialogVisible: false,
        addForm: {
          plate_no_id: 2,
          use_car_date: moment().valueOf(),
          origin_place: '',
          dest_place: '',
          receivable: 0.00,
          payable: 0.00,
          op_cost: 0.00,
          remark: '',
          b_remark: '',
          images: []
        },
        addFormRules: {
          plate_no_id: [
            { required: true, message: '请选择车辆车辆号', trigger: 'blur' }
          ],
          cus_id: [
            { required: true, message: '请选择用车单位', trigger: 'blur' }
          ],
          use_car_date: [
            { required: true, message: '请选择用车日期', trigger: 'blur' }
          ],
          origin_place: [
            { required: true, message: '请输入起始地', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在2-100个字符之间', trigger: 'blur' }
          ],
          dest_place: [
            { required: true, message: '请输入目的地', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在2-100个字符之间', trigger: 'blur' }
          ],
          receivable: [
            { required: true, message: '请输入应收金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ],
          payable: [
            { required: true, message: '请输入应付金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ],
          op_cost: [
            { required: true, message: '请输入用车成本金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ]
        },
        editForm: {

        },
        editFormRules: {

        },
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        tableData: [],
        searchForm: {
          plate_no_id: 0,
          cus_id: 0,
          use_car_date: [moment().subtract(1, 'months').startOf('day').valueOf(), moment().endOf('day').valueOf()]
        },
        userList: [],
        plate_no_list: [],
        plate_no_list_all: [],
        sum_list: [],
        customer_list: [],
        customer_list_all: [],
        images: [],
        uploadForm: {
          file: ''
        },
        fileList: [],
        tableImageData: [],
        file: {},
        isAdminScope: window.sessionStorage.getItem('scope') === 'AdminScope',
        isBalanceScope: window.sessionStorage.getItem('scope') === 'BalanceScope',
        isDispatcherScope: window.sessionStorage.getItem('scope') === 'DispatcherScope'
      }
    },
    created () {
      this.getVehiclefeeList(this.queryInfo)
      this.getVehiclePlateNoList()
      this.getUserList()
      this.getCustomerList()

      this.editFormRules = this.addFormRules
    },
    methods: {
      getImageUrl (path, filename) {
        return this.$config.image_base_url + path + filename
      },
      /** 新增时删除照片 */
      delVehiclePicByGUID: function (index, row) {
        // console.log('row: ' + JSON.stringify(row))
        this.addForm.images.splice(index, 1)
        this.tableImageData.splice(index, 1)

        // console.log('addForm.images: ' + JSON.stringify(this.addForm.images))
        // console.log('tableImageData: ' + JSON.stringify(this.tableImageData))
      },
      /** 删除用车单指定照片 */
      delVehiclePicByID: function (index, row) {
        this.$http.delete(`/image/${row.id}`).then(
          res => {
            this.getVehiclePicList(row.godown_id)
            this.$message.success({ message: res.data.msg })
          }
        )
      },
      /** 自定义合计列算法 */
      getSummaries (param) {
        const columns = param.columns
        const sums = []

        sums[0] = '合计'
        for (const key in this.sum_list) {
          for (let i = 1; i < columns.length; i++) {
            // console.log('column.property = ' + columns[i].property)
            if (columns[i].property === key) {
              sums[i] = this.sum_list[key]
              continue
            }
          }
        }
        return sums
      },
      /** 导出到当前搜索条件结果到Excel中 */
      exportExcel () {
        this.fullscreenLoading = true
        this.$http.get('/vehiclefee/excel', { params: this.searchForm, responseType: 'blob' }).then(
          res => {
            console.log(res)
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
            const fileName = '用车费用.xls'
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)

            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)

            this.fullscreenLoading = false
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取用户列表信息 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
            // console.log(this.userList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 新增用车单的图片上传 */
      uploadImageForAddVehicle (file, fileList) {
        this.images.file = fileList
        this.fileList = []
        const wfForm = new FormData()
        Object.entries(this.images).forEach(file => {
          file[1].forEach(item => {
            wfForm.append('file', item.raw)
            wfForm.append(item.name, file[0])
          })
        })
        this.$http.post('/upload', wfForm, { headers: { enctype: 'multipart/form-data' } }).then(
          res => {
            const image = res.data.data
            // console.log(image)
            // console.log('---------')
            image.from_obj = 'vehicle'
            image.create_time = moment().valueOf() / 1000
            image.creator = window.sessionStorage.getItem('nickname')
            // console.log(image)
            this.tableImageData.push(image)
            this.addForm.images.push(image)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 上传照片，更新用车单时 */
      uploadImage: function (file, fileList) {
        this.images.file = fileList
        this.fileList = []
        const wfForm = new FormData()
        Object.entries(this.images).forEach(file => {
              file[1].forEach(item => {
              wfForm.append('file', item.raw)
              wfForm.append(item.name, file[0])
            })
        })
        this.$http.post('/upload', wfForm, { headers: { enctype: 'multipart/form-data' } }).then(
          res => {
            const image = res.data.data
            image.godown_id = this.editForm.id
            image.name = image.filename
            image.from_obj = 'vehicle'

            this.addPicToVehicle(JSON.stringify(image))
          }
        )
      },
      /** 添加用车单附件 */
      addPicToVehicle: function (strJson) {
        this.$http.post('/image', strJson).then(
          res => {
            this.getVehiclePicList(this.editForm.id)
            this.$message.success({ message: res.data.msg })
          }
        )
      },
      /** 获取用车单附件列表 */
      getVehiclePicList: function (gid) {
        this.$http.get(`/image/vehicle/${gid}`).then(
          res => {
            this.tableImageData = res.data.data.rows
            // console.log('this.tableImageData :', this.tableImageData)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 添加或者更新车辆费用信息，通过用户ID来判断 */
      saveUpdateVehiclefee (form) {
        const _form = JSON.parse(JSON.stringify(form))
        _form.use_car_date /= 1000

        // console.log(_form)

        this.$http.post('/vehiclefee', _form).then(
          res => {
            // console.log('res :' + res)
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            this.editDialogVisible = false

            this.tableImageData = []
            this.addForm = {}
            this.getVehiclefeeList(this.queryInfo)
          },
          err => {
            // console.log('err.response.data :' + JSON.stringify(err.response.data))
            this.$message.error({ message: err.response.data.msg })
          })
      },
       /** 新增车辆费用 */
      addVehiclefee () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateVehiclefee(this.addForm)
        })
      },
      /** 更新车辆费用 */
      updateVehiclefee () {
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return
          this.editForm.images = this.tableImageData
          this.saveUpdateVehiclefee(this.editForm)
        })
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化客户名称 */
      formatCustomerName: function (row, column, cellValue) {
        let customer
        for (let i = 0; i < this.customer_list.length; i++) {
          customer = this.customer_list[i]
          if (customer.id === cellValue) {
            return customer.name
          }
        }
        return '未知'
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      },
      /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getVehiclefeeList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getVehiclefeeList(this.queryInfo)
      },
      /** 格式化车牌名称 */
      formatVehiclePlateName (row, column, cellValue) {
        let plateName
        for (let i = 0; i < this.plate_no_list.length; i++) {
          plateName = this.plate_no_list[i]
          if (plateName.id === cellValue) {
            return plateName.plate_no
          }
        }
      },
      /** 显示编辑车辆费用信息的对话框 */
      handleEdit (index, row) {
        // console.log(row)
        this.editDialogVisible = true
        this.$http.get(`/vehiclefee/${row.id}`).then(
          res => {
            const vehiclefee = JSON.parse(JSON.stringify(res.data.data))
            vehiclefee.use_car_date *= 1000

            this.editForm = vehiclefee
            // console.log("this.editForm :", this.editForm.id);
            this.getVehiclePicList(row.id)
            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除指定的车辆费用记录 */
      handleDelete: function (index, row) {
        if (row.receivable > 0 && this.isDispatcherScope) {
          this.$message.warning({ message: '您无权删除该记录！' })
          return
        }
        this.$http.delete(`/vehiclefee/${row.id}`).then(
          res => {
            this.getVehiclefeeList(this.queryInfo)
            this.$message.success({ message: res.data.msg })
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 监听添加车辆对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 监听修改车辆对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 搜索事件 */
      searchVehiclefee () {
        this.getVehiclefeeList(this.queryInfo)
      },
      /** 获取车辆费用列表 */
      getVehiclefeeList (queryInfo) {
        const endTime = this.searchForm.use_car_date
        if (endTime) {
          this.searchForm.use_car_date[1] = moment(endTime[1]).endOf('day').valueOf() /** 选中当天23:59:59的毫秒数 */
        }
        this.$http.post(`/vehiclefee/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
            this.sum_list = res.data.data.sum
            // console.log(this.tableData)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取客户列表 */
      getCustomerList: function () {
        this.$http.get('/customer/list').then(
          res => {
            this.customer_list = res.data.data.rows
            this.customer_list_all = JSON.parse(JSON.stringify(this.customer_list))
            this.customer_list_all.unshift({ id: 0, name: '全部' })
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取车牌信息列表 */
      getVehiclePlateNoList () {
        this.$http.get('/vehicle/list').then(
          res => {
            this.plate_no_list = res.data.data.rows
            // console.log(this.plate_no_list)
            this.plate_no_list_all = JSON.parse(JSON.stringify(this.plate_no_list))
            this.plate_no_list_all.unshift({ id: 0, plate_no: '全部' })
          },
          err => {
            this.$message.error(err.response.data.msg)
          }
        )
      }
    }
  }
</script>

<style scoped>

</style>
