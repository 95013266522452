<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
<!--       <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>-->
       <el-table
        :data="tableData"
        stripe
        style="width: 100%">
         <el-table-column type="expand">
          <template>
            <el-form label-position="left">
            </el-form>
          </template>
         </el-table-column>
        <el-table-column
          prop="name"
          label="角色"
          width="120">
        </el-table-column>
        <el-table-column
          prop="remark"
          label="角色说明">
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="更新时间" :formatter="formatDatetime" width="180">
        </el-table-column>
<!--        <el-table-column label="操作" width="280">-->
<!--          <el-button-group>-->
<!--            <el-button type="primary" size="mini" icon="el-icon-edit">编辑</el-button>-->
<!--            <el-button type="danger" size="mini" icon="el-icon-delete">删除</el-button>-->
<!--            <el-button type="warning" size="mini" icon="el-icon-setting">分配权限</el-button>-->
<!--          </el-button-group>-->
<!--        </el-table-column>-->
      </el-table>
      <el-dialog title="添加角色" :visible.sync="addDialogVisible" @close="addDialogClosed">
        <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="80px">
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model="addForm.roleName" ></el-input>
          </el-form-item>
          <el-form-item label="角色描述"  prop="remark">
            <el-input v-model="addForm.remark" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRole">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Roles',
    data () {
      return {
        tableData: [],
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        addDialogVisible: false,
        addForm: {
          id: 0,
          roleName: '',
          remark: ''
        },
        addFormRules: {
          roleName: [
            {
              required: true,
              message: '请输入角色名称',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 32,
              message: '长度在2～32个字符之间',
              trigger: 'blur'
            }
          ],
          remark: [
            {
              required: true,
              message: '请输入角色描述',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 200,
              message: '长度在2～200个字符之间',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    created: function () {
      this.getRoleList(this.queryInfo)
    },
    methods: {
      /** 获取角色列表 */
      getRoleList (queryInfo) {
        this.$http.get(`/role/pagination/${queryInfo.page}/${queryInfo.pageSize}?${this.select}=${queryInfo.query}`).then(
          res => {
            // console.log('res :', res)
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 监听添加用户对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 添加或者更新角色信息，通过用户ID来判断 */
      saveUpdateRole (form) {
        this.$http.post('/role', JSON.stringify(form)).then(
          res => {
            // console.log('res :' + res)
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            // this.editDialogVisible = false
            this.getRoleList(this.queryInfo)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          })
      },
      /** 添加角色 */
      addRole () {
        this.$refs.addFormRef.validate(valid => {
        if (!valid) return
          this.saveUpdateRole(this.addForm)
        })
      }
    }

  }
</script>

<style lang="less" scoped>

</style>
