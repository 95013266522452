import { render, staticRenderFns } from "./Leave.vue?vue&type=template&id=7be6156f&scoped=true&"
import script from "./Leave.vue?vue&type=script&lang=js&"
export * from "./Leave.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7be6156f",
  null
  
)

export default component.exports