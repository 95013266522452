<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>考勤管理</el-breadcrumb-item>
      <el-breadcrumb-item>考勤列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--   搜索Form表单区域   -->
    <el-card>
      <el-form v-model="searchForm" :inline="true">
        <el-form-item label="部门">
          <el-select v-model="searchForm.dept_id" placeholder="请选择部门" @change="onDepartmentChange" @clear="onClearDepartment" clearable style="width: 160px">
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工">
          <el-select v-model="searchForm.user_id" placeholder="请选择员工" @clear="onClearUser" clearable style="width: 160px">
            <el-option
              v-for="item in departmentUserList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchForm.type" placeholder="请选择打卡类型" @clear="onClearType" clearable style="width: 160px">
            <el-option
              v-for="item in attendanceType"
              :key="item.value"
              :label="item.key"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考勤日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.attendance_date" type="daterange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   员工列表区域   -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchAttendance" size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="exportExcel" size="small" icon="el-icon-document">导出EXCEL</el-button>
        <!--el-button type="primary" @click="addDialogVisible=true" size="small" icon="el-icon-plus">新增</el-button-->
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
        <el-table-column label="考勤日期" prop="attendance_date" width="120px" :formatter="formatDate"></el-table-column>
        <el-table-column label="员工姓名" prop="name" width="120px" :formatter="formatUserName"></el-table-column>
        <el-table-column label="考勤类型" prop="type" width="100px" :formatter="formatTypeName"></el-table-column>
        <el-table-column label="打卡时间" prop="clock_time" width="165px" :formatter="formatDatetime"></el-table-column>
        <el-table-column label="照片" prop="image_url" width="120px">
          <template slot-scope="scope">
            <el-image
              :preview-src-list="[scope.row.image_url]"
              style="width: 100px; height: 50px; margin-bottom:-4px"
              :src="`${scope.row.image_url}`"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="打卡地址" prop="address" width="300px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="备注" prop="remark" width="300px" :show-overflow-tooltip="true"></el-table-column>
        <!--el-table-column width="200px" fixed="right" align="left" label="操作">
           <template slot-scope="scope">
             <el-button-group>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="handleDelete(scope.$index, scope.row)">
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    slot="reference">删除</el-button>
              </el-popconfirm>
             </el-button-group>
          </template>
        </el-table-column-->
      </el-table>
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Attendance',
  data () {
    return {
      fullscreenLoading: false,
      searchForm: {
        dept_id: null,
        user_id: null,
        type: null,
        attendance_date: [this.$moment().subtract(1, 'months').valueOf(), this.$moment().valueOf()]
      },
      tableData: [],
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        total: 0
      },
      userList: [],
      departmentList: [],
      departmentUserList: [],
      curDepartment: null,
      attendanceType: [{ value: 1, key: '考勤打卡' }, { value: 2, key: '外勤签到' }]
    }
  },
  created: function () {
    this.getAttendanceList(this.queryInfo)
    this.getUserList()
    this.getDepartmentList()
  },
    methods: {
      exportExcel () {
        this.fullscreenLoading = true
        this.$http.get('/attendance/excel', { params: this.searchForm, responseType: 'blob' }).then(
          res => {
            console.log(res)
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
            const fileName = '考勤管理.xls'
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)

            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)

            this.fullscreenLoading = false
          },
          err => {
            this.$message.error(err.response.data.msg)
          }
        )
      },
      /** 查询考勤列表 */
      searchAttendance: function () {
        this.getAttendanceList(this.queryInfo)
      },
      /** 获取考勤列表 */
      getAttendanceList (queryInfo) {
        const endTime = this.searchForm.attendance_date
        if (endTime) {
          this.searchForm.attendance_date[1] = moment(endTime[1]).endOf('day').valueOf()
        }
        this.$http.post(`/attendance/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取公司部门列表 */
      getDepartmentList: function () {
        this.$http.get('/department/list').then(
          res => {
            this.departmentList = res.data.data.rows
            // console.log('this.userList:', this.userList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取部门用户列表 */
      getDepartmentUserList: function () {
        this.$http.get(`/user/list/${this.curDepartment}`).then(
          res => {
            this.departmentUserList = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取用户列表 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      onClearDepartment (e) {
        this.searchForm.dept_id = null
      },
      onClearUser (e) {
        this.searchForm.user_id = null
      },
      onClearType (e) {
        this.searchForm.type = null
      },
      onDepartmentChange (e) {
        this.curDepartment = e
        if (this.curDepartment) {
          this.searchForm.user_id = null
          this.getDepartmentUserList()
        } else {
          this.departmentUserList = null
        }
      },
      /** 改变列表显示记录数触发事件 */
      handleSizeChange (val) {
        this.queryInfo.pageSize = val
        this.getAttendanceList(this.queryInfo)
      },
      handleCurrentChange (val) {
        this.queryInfo.page = val
        this.getAttendanceList(this.queryInfo)
      },
      /** 格式化用户名称 */
      formatUserName: function (row) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          // console.log('i=' + i)
          if (user.id === row.user_id) {
            // console.log(customer)
            return user.nickname
          }
        }
      },
      /** 格式化时间戳为标准的日期字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化类型名称 */
      formatTypeName: function (row) {
        return row.type === 1 ? '考勤打卡' : '外勤签到'
      }
    }
}
</script>

<style scoped>

</style>
